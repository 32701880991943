import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { showModal } from 'shared/foreground/actions/modals';
import { getView } from 'shared/foreground/stateGetters';
import {
  addPinnedFeed,
  removeFeedIdFromSidebar,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { setFeedIdsToAddInFolder } from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { RssFeed, RssFolder } from 'shared/types';

import ChevronDownSmall from '../icons/ChevronDownSmallIcon';
import { modalId as addRssFolderModalId } from '../Modals/AddRssFolderModal';
import Tag from '../Tag';
import { getSeparatorOption, getTitleOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';
import styles from './FeedViewsDropdown.module.css';

const Trigger = ({
  associatedFolders,
  isFocused,
}: { associatedFolders?: RssFolder[]; isFocused: boolean; }) => {
  const getFolderFilteredViewName = useCallback((folder: RssFolder) => {
    const folderFilteredView = getView(folder.filteredViewId);
    return folderFilteredView?.name || 'No name';
  }, []);

  const associatedFoldersWithName = useMemo(() => {
    return associatedFolders?.map((folder) => ({
      ...folder,
      name: getFolderFilteredViewName(folder),
    })).sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
  }, [associatedFolders, getFolderFilteredViewName]);

  return (
    <>
      <div className={styles.viewsTags}>
        {associatedFoldersWithName?.length
          ? associatedFoldersWithName.map((folder) => {
            return (
              <Tag key={folder.id} className={styles.viewTag}>
                {folder.name}
              </Tag>
            );
          })
         : <span className={styles.noViews}>Manage folders...</span>
        }
      </div>
      {isFocused &&
        <span className={styles.chevronWrapper}>
          <ChevronDownSmall />
        </span>
      }
    </>
  );
};

export default React.memo(function FeedViewsDropDown({
  feedId,
  feed,
  rssFolders,
  associatedFolders,
  isFocused = false,
}: {
  feedId: string;
  feed: RssFeed;
  rssFolders?: RssFolder[];
  associatedFolders?: RssFolder[];
  isFocused: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (window.isRadixDropdownOpen === isOpen) {
      return;
    }

    window.isRadixDropdownOpen = isOpen;

    return () => {
      window.isRadixDropdownOpen = false;
    };
  }, [isOpen]);

  const TriggerElement =
    <DropdownMenu.Trigger className={`${styles.dropdownTrigger} ${isOpen ? styles.isOpen : ''}`}>
      <Trigger associatedFolders={associatedFolders} isFocused={isFocused} />
    </DropdownMenu.Trigger>;
  const getFolderFilteredViewName = useCallback((folder: RssFolder) => {
    const folderFilteredView = getView(folder.filteredViewId);
    return folderFilteredView?.name || 'No name';
  }, []);

  const foldersOptions = useMemo(
    () =>
      rssFolders?.map((folder) => {
        const checked = Boolean(associatedFolders?.find((f) => f.id === folder.id));

        return {
          type: DropdownOptionType.Checkbox,
          name: getFolderFilteredViewName(folder),
          checked,
          setChecked: (isChecked: boolean) => {
            if (isChecked) {
              addPinnedFeed({
                rssSourceId: feedId,
                order: Infinity,
                rssFolderId: folder.id,
                options: { userInteraction: 'click' },
              });
            } else {
              removeFeedIdFromSidebar({
                feedId,
                rssFolderId: folder.id,
                options: { userInteraction: 'click' },
              });
            }
          },
        };
      }) ?? [],
    [associatedFolders, rssFolders, feedId, getFolderFilteredViewName],
  );

  const options = useMemo(
    () => [
      getTitleOption(`Include documents from “${feed.name}” in the following folders`, styles.itemTitle),
      getSeparatorOption(),
      {
        type: DropdownOptionType.ScrollableItems,
        childOptions: foldersOptions,
      },
      getSeparatorOption(),
      {
        type: DropdownOptionType.Item,
        isCreate: true,
        name: 'Create a new folder from feed',
        onSelect: () => {
          setFeedIdsToAddInFolder([feedId], { userInteraction: 'click' });
          showModal({ id: addRssFolderModalId }, { userInteraction: 'click' });
        },
      },
    ],
    [feed.name, foldersOptions, feedId],
  );

  // Appending a lot of dropdowns to the body makes the transition to this view
  // a bit slow. So we are going to append it only after the user actually clicks it.

  if (isOpen) {
    return (
      <Dropdown
        trigger={TriggerElement}
        options={options}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        sideOffset={10}
        scrollableOptionsClassName={styles.feedScrollableOptions}
        appendToDocumentBody
      />
    );
  }

  return (
    <button type="button" className={styles.dropdownTrigger} onClick={() => setIsOpen(true)}>
      <Trigger associatedFolders={associatedFolders} isFocused={isFocused} />
    </button>
  );
});
